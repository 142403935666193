import { httpClientBot, httpPlainBotApi } from "@/libraries";

const state = {
  assistant: null,
  listAssistant: [],
  files: [],
  playground: [],
  vectorFiles: [],
  assistantMessage: false,
  models: [],
  vector: [],
};

const getters = {
  assistant(state) {
    return state.listAssistant;
  },
  assistantById(state) {
    return state.assistant;
  },
  files(state) {
    return state.files;
  },
  playground(state) {
    return state.playground;
  },
  vector(state) {
    return state.vector;
  },
  vectorFiles(state) {
    return state.vectorFiles;
  },
  assistantMessage(state) {
    return state.assistantMessage;
  },
  threadUnique(state) {
    return state.thread_unique;
  },
  getModels(state) {
    return state.models;
  },
};

const mutations = {
  SET_ASSISTANT(state, data) {
    state.listAssistant = data;
  },
  SET_ASSISTANT_BY_ID(state, data) {
    state.assistant = data;
  },
  SET_FILES(state, data) {
    state.files = data;
  },
  ADD_PLAYGROUND(state, data) {
    state.playground.push(data);
  },
  CLEAR_PLAYGROUND(state) {
    state.playground = [];
  },
  MOD_PLAYGROUND(state, data) {
    state.playground[state.playground.length - 1].message += data;
  },
  SET_VECTOR(state, data) {
    state.vector = data;
  },
  SET_VECTORFILES(state, data) {
    state.vectorFiles = data;
  },
  SET_ASSISTANT_MESSAGE(state, data) {
    state.assistantMessage = data;
  },
  SET_MODELS(state, data) {
    state.models = data;
  },
  SET_THREAD_UNIQUE(state, data) {
    state.thread_unique = data;
  },
};

const actions = {
  async listAssistant({ commit, rootGetters }) {
    const resp = await httpPlainBotApi.get(
      rootGetters.appId.hashed + "/assistant"
    );
    // console.log(resp.data.data.data);
    commit("SET_ASSISTANT", resp.data.data);
    return resp.data.data;
  },
  async create({ rootGetters }, payload) {
    const resp = httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/assistant",
      payload
    );
    return resp;
  },
  async getById({ rootGetters }, id) {
    const resp = await httpPlainBotApi.get(
      "/" + rootGetters.appId.hashed + "/assistant/detail/" + id
    );
    return resp.data.data;
  },
  async update({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.put(
      "/" + rootGetters.appId.hashed + "/assistant/" + payload.id,
      payload
    );
    return resp.data.data;
  },
  async delete({ rootGetters }, id) {
    const resp = await httpPlainBotApi.delete(
      "/" + rootGetters.appId.hashed + "/assistant/" + id
    );
    console.log(resp);
  },
  async getFile({ rootGetters, commit }, params = null) {
    let type = "";
    if (params) {
      if (params.type) {
        type = `?type=${params.type}`;
      }
    }
    const resp = await httpPlainBotApi.get(
      "/" + rootGetters.appId.hashed + "/assistant/file" + type
    );
    commit("SET_FILES", resp.data.data);
  },
  async uploadFile({ rootGetters, commit }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/assistant/file",
      payload
    );
    console.log(resp);
    return resp.data;
  },
  async deleteFile({ rootGetters, commit }, id) {
    const resp = httpPlainBotApi.delete(
      "/" + rootGetters.appId.hashed + "/assistant/file/" + id
    );
    console.log(resp);
  },
  async attach({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/assistant/attach-file",
      payload
    );
    console.log(resp);
  },
  async detach({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/assistant/detach-file",
      payload
    );
    console.log(resp);
    return resp.data;
  },
  async sendPlayground({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/assistant/playground/send",
      payload
    );
    return resp.data.data;
  },
  clearPlayground({ commit }) {
    commit("CLEAR_PLAYGROUND");
  },
  async createVector({ rootGetters }, payload) {
    await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/vector-store",
      payload
    );
  },
  async getvectorFile({ rootGetters, commit }) {
    const resp = await httpPlainBotApi.get(
      "/" + rootGetters.appId.hashed + "/vector-store"
    );
    commit("SET_VECTORFILES", resp.data.data);
  },
  async deletevectorFile({ rootGetters, commit }, id) {
    const resp = httpPlainBotApi.delete(
      "/" + rootGetters.appId.hashed + "/vector-store/" + id
    );
    console.log(resp);
  },
  async attachfileVector({ rootGetters, commit }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/vector/" + payload.id,
      {
        file: payload.file,
        assistant_id: payload.assistant_id,
      }
    );

    commit("SET_VECTOR", resp.data);
    return resp.data;
  },
  async detachfileVector({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/vector/" + payload.id + "/detach",
      {
        file: payload.file,
        assistant_id: payload.assistant_id,
      }
    );
    return resp;
  },
  async updateVectorName({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.put(
      "/" + rootGetters.appId.hashed + "/vector-store/" + payload.id.id,
      { name: payload.name }
    );
    console.log(resp);
  },
  async crawl({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/nlp/crawl",
      { url: payload.url }
    );
    return resp;
  },
  async scrape({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/nlp/scrape-v2",
      payload
    );
    return resp;
  },
  async exportLogs({ rootGetters }, payload) {
    const resp = await httpPlainBotApi.post(
      "/" + rootGetters.appId.hashed + "/bot/logs/export",
      payload
    );
    return resp;
  },
  async generatePrompt(_, payload) {
    const resp = await httpClientBot.post("/assistant/prompt", payload);
    return resp.data;
  },
  async getModels({ rootGetters, commit }) {
    const resp = await httpPlainBotApi.get(
      "/" + rootGetters.appId.hashed + "/model"
    );
    commit("SET_MODELS", resp.data.data);
    return resp.data.data;
  },
  threadUnique(payload) {
    commit("SET_THREAD_UNIQUE", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
