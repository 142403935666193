export default {
  path: "/:appId/studio",
  name: "conversation-studio",
  component: () =>
    import("@/views/conversation-studio/pages/ConversationStudioMain.vue"),
  redirect: { name: "conversation-studio.main" },
  props: true,
  children: [
    {
      path: "main",
      name: "conversation-studio.main",
      component: () =>
        import("@/views/conversation-studio/pages/MainBotstudio.vue"),
      props: true,
      meta: {
        title: "Manage Bot",
      },
    },
    {
      path: "intent",
      name: "conversation-studio.intent",
      component: () =>
        import("@/views/conversation-studio/pages/Intent/StoryGroups.vue"),
      redirect: { name: "conversation-studio.intent.story-groups" },
    },
    {
      path: "intent/story-group",
      name: "conversation-studio.intent.story-groups",
      meta: {
        title: "Story Group",
      },
      component: () =>
        import("@/views/conversation-studio/pages/Intent/StoryGroups.vue"),
      props: true,
    },
    {
      path: "intent/active-stories",
      name: "conversation-studio.intent.active-stories",
      component: () =>
        import("@/views/conversation-studio/pages/Intent/ActiveStories.vue"),
      meta: {
        title: "Active Stories",
      },
    },
    {
      path: "intent/function-list",
      name: "conversation-studio.intent.function-list",
      component: () =>
        import("@/views/conversation-studio/pages/Intent/FunctionList.vue"),
      meta: {
        title: "Function List",
      },
    },
    {
      path: "intent/machine-reading",
      name: "conversation-studio.intent.machine-reading",
      component: () =>
        import("@/views/conversation-studio/pages/Intent/ReadingLibrary.vue"),
      meta: {
        title: "Reading Library",
      },
    },
    {
      path: "intent/machine-reading/scraping",
      name: "conversation-studio.intent.machine-reading.scraping",
      component: () =>
        import(
          "@/views/conversation-studio/components/reading-library/Scraping.vue"
        ),
      meta: {
        title: "Bot Reading Form",
      },
    },
    {
      path: "intent/machine-reading/scraping/:readingLibraryId",
      name: "conversation-studio.intent.machine-reading.scraping-detail",
      component: () =>
        import(
          "@/views/conversation-studio/components/reading-library/Scraping.vue"
        ),
      meta: {
        title: "Bot Reading Form",
      },
    },
    {
      path: "active-stories/:id/editor",
      name: "conversation-studio.intent.stories-editor",
      component: () =>
        import("@/views/conversation-studio/pages/Intent/StoryEditor.vue"),
      meta: {
        title: "Story Editor",
      },
    },
    {
      path: "nlu",
      name: "conversation-studio.nlu",
      redirect: {
        name: "conversation-studio.nlu.entities",
      },
      component: () =>
        import("@/views/conversation-studio/pages/NLU/entities/Entities.vue"),
    },
    {
      path: "nlu/entities",
      name: "conversation-studio.nlu.entities",
      component: () =>
        import("@/views/conversation-studio/pages/NLU/entities/Entities.vue"),
      meta: {
        title: "Entities",
      },
    },
    {
      path: "nlu/voice-replace",
      name: "conversation-studio.nlu.voice-replace",
      component: () =>
        import(
          "@/views/conversation-studio/pages/NLU/word-replaces/WordReplacesMain.vue"
        ),
      meta: {
        title: "Voice Word Replaces",
      },
    },
    {
      path: "nlu/synonyms",
      name: "conversation-studio.nlu.synonyms",
      component: () =>
        import("@/views/conversation-studio/pages/NLU/synonym/SynonymMain.vue"),
      meta: {
        title: "Synonym",
      },
    },
    {
      path: "nlu/fallback-list",
      name: "conversation-studio.nlu.fallback-list",
      component: () =>
        import(
          "@/views/conversation-studio/pages/NLU/fallback/FallbackMain.vue"
        ),
      meta: {
        title: "Fallback List",
      },
    },
    {
      path: "setting",
      name: "conversation-studio.setting",
      // component: GeneralSetting,
      redirect: {
        name: "conversation-studio.setting.response",
      },
      component: () =>
        import("@/views/conversation-studio/pages/Setting/GeneralSetting.vue"),
    },
    {
      path: "setting/response",
      name: "conversation-studio.setting.response",
      meta: {
        title: "Bot Configuration",
      },
      component: () =>
        import("@/views/conversation-studio/pages/Setting/GeneralSetting.vue"),
    },
    {
      path: "setting/:botId",
      name: "conversation-studio.setting.detail",
      meta: {
        title: "Bot Configuration",
      },
      component: () =>
        import("@/views/conversation-studio/pages/Setting/GeneralSetting.vue"),
    },
    {
      path: "persona",
      name: "conversation-studio.setting.persona",
      // component: Persona,
      meta: {
        title: "Bot Configuration",
      },
      component: () =>
        import("@/views/conversation-studio/pages/Setting/Persona.vue"),
    },
    // {
    //   path: "setting",
    //   name: "conversation-studio.setting",
    //   redirect: {
    //     name: "conversation-studio.setting.response",
    //   },
    //   // component: MenuPage,
    //   component: () =>
    //     import("@/views/conversation-studio/pages/ConversationStudioMain.vue"),
    //   children: [

    //
    //   ],
    // },
  ],
};
