import GeneralSettingService from "@/services/conversation-studio/general_setting_service";

const Types = {
  SET_BOT_CONFIGURATION: "SET_BOT_CONFIGURATION",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
};

const state = {
  botConfiguration: [],
  error: null,
  loading: false,
};

const getters = {
  botConfiguration(state) {
    return state.botConfiguration;
  },
};

const mutations = {
  [Types.SET_BOT_CONFIGURATION]: (state, payload) =>
    (state.botConfiguration = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
};

const actions = {
  async getBotConfiguration({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } =
        await GeneralSettingService.getBotConfigurationProfile();
      let resp = response;
      resp.avatar = JSON.parse(response.avatar);
      resp.intent_confidence_threshold = resp.intent_confidence_threshold * 10;
      resp.entity_confidence_threshold = resp.entity_confidence_threshold * 10;
      commit(Types.SET_BOT_CONFIGURATION, resp);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getById({ commit, rootGetters }, id) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await GeneralSettingService.getBotById(
        rootGetters.appId.hashed,
        id
      );
      let resp = response;
      resp.avatar = JSON.parse(response.avatar);
      resp.intent_confidence_threshold = resp.intent_confidence_threshold * 10;
      resp.entity_confidence_threshold = resp.entity_confidence_threshold * 10;
      commit(Types.SET_BOT_CONFIGURATION, resp);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async deleteAvatar({ commit }, image) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await GeneralSettingService.removeAvatar(image);
      return response.data.message;
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async saveAvatar({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await GeneralSettingService.saveAvatar(payload);
      return response.data.message;
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async updateBotConfig({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await GeneralSettingService.updateBotConfig(payload);
      return response.data.message;
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async changeStatus({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await GeneralSettingService.changeStatus(payload);
      return response.data.message;
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
